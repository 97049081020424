<template>
  <div id="intelligentDiagnosis">
    <div class="pages hidden" id="intelligentDiagnosisPages">
      <div class="page_title"><span class="text hidden" id="intelligentDiagnosisText"></span></div>
      <div class="page_date">{{ updateDate }}</div>

      <!-- 主力资金 -->
      <div ref="refMainStrengthHolder" v-if="isMainStrengthChartVisible">
          <bar-chart ref="refMainStrengthChart" id="mainStrengthChart" customClass="mainStrengthChart"></bar-chart>
          <div class="score">{{ currentData.stMainScore }}</div>
          <div class="desc">{{ currentData.stMainScoreDesc }}</div>
      </div>

      <!-- 人气指数 -->
      <div ref="refMarketHotChartHolder" v-if="isMarketHotChartVisible">
          <self-line-chart ref="refMarketHotChart" id="marketHot" customClass="self_link_chart" />
          <div class="desc">{{ currentData.popularityDesc }}</div>
          <div class="score">{{ currentData.stMktHotScore }}</div>
          <div class="desc">{{ currentData.stMktHotScoreDesc }}</div>
      </div>

      <!-- 机构调研 -->
      <div ref="refInsResChartHolder" v-if="isInsResChartVisible">
          <chart-legend ref="refInsResChartLegend" id="insResChartLegend" class="common_legend" />
          <bar-double-chart ref="refInsResChart" id="insResChart" customClass="bar_double_chart" />
          <div class="desc">{{ currentData.insResDescription }}</div>
      </div>
      <div ref="refTrendAnalyzeHolder" v-if="isTrendAnalyzeVisible">
        <chart-legend ref="refTrendAnalyzeLegend" id="trendAnalyzeLegend" class="common_legend" />
        <line-chart ref="refTrendAnalyzeChart" id="TrendAnalyzeChart" customClass="line_chart" />
        <div class="desc">{{ currentData.desc }}</div>
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import selfLineChart from "@/components/selfLineChart/index.vue";
import barDoubleChart from "@/components/barDoubleChart/index.vue";
import chartLegend from "@/components/chartLegend/index.vue";
import barChart from "@/components/barChart/index_v1.0.vue";
import lineChart from "@/components/lineChart/index_v1.0.vue";
export default {
  data: function () {
    return {
      titleText: null,  // 动画句柄
      updateDate: "",  // 数据更新日期
      isMainStrengthChartVisible: false,
      isMarketHotChartVisible: false,
      isInsResChartVisible: false,
      isTrendAnalyzeVisible: false,
      colorArr: ["#65BBFF", "#FF3B30", "#FF8000", "#FF64C1", "#FFC400", "#FF5A37", "#F87707"],
      currentData: {},
    };
  },
  components: {
    selfLineChart,
    barDoubleChart,
    chartLegend,
    barChart,
    lineChart
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        if (this.titleText) {
          this.titleText.runOut();
        }
        await sleep(200);
        this.isMainStrengthChartVisible = false
        this.isMarketHotChartVisible = false
        this.isInsResChartVisible = false
        this.isTrendAnalyzeVisible = false
        $("#intelligentDiagnosisText").addClass("hidden");
        this.updateDate = "";
        resolve();
      });
    },
    async show(commandData) {
      this.isMainStrengthChartVisible = false
      this.isMarketHotChartVisible = false
      this.isInsResChartVisible = false
      this.isTrendAnalyzeVisible = false
      //设置标题
      const title = commandData.data.pageTitle;
      $("#intelligentDiagnosisPages").removeClass("hidden");
      $("#intelligentDiagnosisText").html(title);

      this.$nextTick(async () => {
        $("#intelligentDiagnosisText").removeClass("hidden");
        this.titleText = anyElement({
          targets: document.querySelector("#intelligentDiagnosisText"),
          isSplitTxt: true,
          duration: 600,
          delay: 30,
          easing: "easeOutExpo",
          inEffect: "rightInBig",
          outEffect: "leftOutBig",
        });
        await sleep(300);
        this.titleText.runIn();
      });
      if (commandData.type === '人气指数') {
        this.handleMarketHotChart(commandData)
      }
      if (commandData.type === '主力资金') {
        this.handleMainStrength(commandData)
      }
      if (commandData.type === '机构调研') {
        this.handleInsResChart(commandData)
      }
      if (['市场表现', '板块潜力'].indexOf(commandData.type) !== -1) {
        this.handleTrendAnalyze(commandData)
      }
    },
    handleMarketHotChart(commandData) {
      this.isMarketHotChartVisible = true;
      this.currentData = commandData.data.data
      this.$nextTick(() => {
        this.renderMarketHotChart(commandData);
      });
    },
    renderMarketHotChart(commandData) {
      let width = this.$refs.refMarketHotChartHolder.clientWidth;
      if (commandData.data.data.chartData.length > 1) {
        this.$refs.refMarketHotChart.selfLineChart(commandData.data.data.chartData, {
            width: width,
            height: width * 0.65,
            marginLeft: 0,
            marginRight: 50,
            marginTop: 10,
            marginBottom: 40,
            duration: 1500,
            delay: 20,
        });
      }
    },
    handleMainStrength(commandData) {
      this.isMainStrengthChartVisible = true
      this.currentData = commandData.data.data
      this.$nextTick(() => {
        this.renderMainStrength(commandData)
      })
    },
    renderMainStrength(commandData) {
      let width = this.$refs.refMainStrengthHolder.clientWidth;
      this.$refs.refMainStrengthChart.BarChart(commandData.data.data.chartData, {
          x: (d) => d.label,
          y: (d) => Number(d.value1),
          width: width,
          height: width * 0.68,
          marginLeft: 50,
          marginRight: 30,
          marginTop: 40,
          marginBottom: 50,
          duration: 1000,
          delay: 70,
          ease: "easeCircle",
          isShowNumber: true,
          xTickNumber: 5,
          yTickNumber: 5,
      });
    },
    handleInsResChart(commandData) {
      this.isInsResChartVisible = true
      this.currentData = commandData.data.data
      this.$nextTick(() => {
        this.renderInsResChart(commandData)
      })
    },
    renderInsResChart(commandData) {
      //展示legend
      this.$refs.refInsResChartLegend.createLegend({
          legend: commandData.data.data.legend,
          legendType: "bar",
          colors: this.colorArr,
      });
      let width = this.$refs.refInsResChartHolder.clientWidth;
      this.$refs.refInsResChart.BarChart(commandData.data.data.chartData, {
          x: (d) => d.label,
          width: width,
          height: width * 0.67,
          marginLeft: 50,
          marginRight: 30,
          marginTop: 50,
          marginBottom: 50,
          xPadding: 0.8,
          yTickSize: 0,
          duration: 800,
          delay: 80,
          ease: "easeBack",
          colors: this.colorArr,
          xTickNumber: commandData.data.data.length,
          yTickNumber: 4,
          isShowText: false,
      });
    },
    handleTrendAnalyze(commandData) {
      this.isTrendAnalyzeVisible = true
      this.currentData = commandData.data.data
      this.$nextTick(() => {
        this.renderTrendAnalyze(commandData)
      })
    },
    renderTrendAnalyze(commandData) {
      let width = this.$refs.refTrendAnalyzeHolder.clientWidth;
      let data = commandData.data.data.chartData
      data = data[0].map((item, index) => {
        let obj = {
          label: item.name
        }
        data.forEach((value, i) => {
          obj[`value${i + 1}`] = data[i][index].value
        })
        return obj
      })
      this.$refs.refTrendAnalyzeLegend.createLegend({
        legend: commandData.data.data.legendArr,
        legendType: "line",
        colors: this.colorArr,
      });
      this.$refs.refTrendAnalyzeChart.LineChart(data, {
        label: (d) => d.label,
        width: width,
        height: width * 0.65,
        marginLeft: 60,
        marginRight: 60,
        marginTop: 20,
        marginBottom: 50,
        duration: 1000,
        delay: 80,
        ease: "easeCubic",
        dotCircleRadius: 0,
        isShowTxtTips: false,
        isFromZero: true,
        yTickNumber: 6,
        xTickNumber: 2,
        unit: "%",
        lineColors: this.colorArr
      });
    }
  },

  async mounted() {
    window.hide = this.hide
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
