<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
    };
  },
  methods: {
    async playOut() {
      if (this.svg == null) {
        return;
      }
      this.svg.transition().duration(100).style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    async selfLineChart(
      data,
      {
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain,
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeQuadOut", //元素之间间隔时长
        xPadding = 0,
        unit = ""
      } = {}
    ) {
      this.duration = duration;
      const curve = d3[curveName];
      const X = data.map((d) => d.name);
      if (xDomain === undefined) xDomain = X;
      if (yDomain === undefined) yDomain = [d3.min(data, (d) => d.value) - 10, d3.max(data, (d) => d.value)]

      // 比例尺
      const xScale = d3.scalePoint(xDomain, xRange);
      const yScale = d3.scaleLinear(yDomain, yRange);

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      // 画单位
      const drawUnit = () => {
        svg.append("g").
        attr("class", "text_group")
        .append("text")
        .attr("class", "x_text")
        .text(`单位（${unit}元）`)
        .attr("x", width - marginRight - 40)
        .attr("y", marginTop - 24)
      }
      if(unit){
        drawUnit()
      }
      const drawBorder = () => {
        let path1 = d3.path()
        path1.moveTo(marginLeft, marginTop)
        path1.lineTo(width-marginRight, marginTop);
        path1.closePath();
        let path2 = d3.path()
        path2.moveTo(marginLeft, marginTop)
        path2.lineTo(marginLeft, height - marginBottom);
        path2.closePath();
        svg.append('g').attr('class', 'axis_x_top').append('path').attr('class', 'tick_line').attr('d', path1).attr("stroke", "#C5C5C5").attr("stroke-width", 1)
        svg.append('g').attr('class', 'axis_x_top').append('path').attr('class', 'tick_line').attr('d', path2).attr("stroke", "#C5C5C5").attr("stroke-width", 3)
      }
      drawBorder()
      const drawXY = () => {
        const xAxis =d3.axisBottom(xScale).tickSize(0).tickSizeOuter(0).tickValues([X[0], X[X.length - 1]]);
        const yAxis = d3.axisRight(yScale).tickSize(0).ticks(8);
      // x轴
      const axisX = svg
        .append("g")
        .attr("class", "axis_x_bottom")
        .attr("transform", `translate(0,${height - marginBottom + 1})`)
        .call(xAxis)
        .call((g) => {
          g.select(".domain").attr("opacity", 0);
          g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
          g.selectAll(".tick text")
            .attr("class", (d, i) => {
              return `x_text x_text_${i}`
            })
            .attr("dy", "1em")
            .attr("opacity", 0);
        });
        axisX.selectAll(".domain").transition().duration(600).attr("opacity", 1);
        axisX.selectAll(".tick_line").transition().duration(600).attr("opacity", 1);
        axisX
          .selectAll(".x_text")
          .transition()
          .delay((d, i) => i * delay)
          .ease(d3.easeCircleOut)
          .duration(600)
          .attr("opacity", 1);
      // y轴
      const axisY = svg
        .append("g")
        .attr("class", "axis_y_right")
        .attr("transform", `translate(${width - marginRight},0)`)
        .call(yAxis)
        .call((g) => {
          g.select(".domain").attr("opacity", 0);
          g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
          g.selectAll(".tick text").attr("class", "y_text")
          .attr("dx", "0.3em")
          .attr("opacity", 0);
        });
        axisY.selectAll(".domain").transition().duration(600).attr("opacity", 1);
        axisY.selectAll(".tick_line").transition().duration(600).attr("opacity", 1);
        axisY
          .selectAll(".y_text")
          .transition()
          .delay((d, i) => i * delay)
          .ease(d3.easeCircleOut)
          .duration(600)
          .attr("opacity", 1);
      }
      drawXY()
      
      //画面积
      const drawArea = () => {
        var area = d3
          .area()
          .x(function (d, i) {
            return xScale(d.name);
          }) //对x轴进行缩放
          .y0(height - marginBottom) //定义y0轴
          .y1(function (d) {
            return yScale(d.value);
          }); //对y1轴进行缩放

        //画渐变
        const areaGroup = svg.append("g").attr("class", "area_group");
        const defs = areaGroup.append("defs");
        const linearGradient = defs
          .append("linearGradient")
          .attr("id", "linearColor")
          .attr("x1", "0%")
          .attr("y1", "0%")
          .attr("x2", "0%")
          .attr("y2", "100%");
        linearGradient
          .append("stop")
          .attr("offset", "0%")
          .style("stop-color", "#FF7700 ")
          .style("stop-opacity", "0.49");
        linearGradient
          .append("stop")
          .style("stop-color", "#F87707")
          .attr("offset", "100%")
          .style("stop-opacity", "0.1");

        //画区域图
        areaGroup
          .append("path")
          .attr("d", area([data[0]]))
          .attr("class", "line_area")
          .attr("fill", "url(#" + linearGradient.attr("id") + ")")
          .transition()
          .duration(duration)
          .ease(d3[ease])
          .attrTween("d", () => {
            let index = d3.interpolate(0, data.length - 1);
            return (t) => {
              let deg = Math.round(index(t));
              return area(data.slice(0, deg + 1));
            };
          });
      };
      drawArea();

       //画线
      const drawLine = () => {
        const Y = data.map((d) => d.value)
        const lineGroup = svg.append("g").attr("class", "line_group");
        const pathLine = d3.line().curve(curve).x((i) => xScale(X[i])).y((i) => yScale(Y[i]));
        const svgLine = lineGroup
            .append("path")
            .attr("fill", "none")
            .attr("class", 'line')
            .attr("stroke", "#FF7700")
            .attr("stroke-width", "2")
            .attr("d", pathLine(d3.range(Y.length)));
        const svgLineTotalLength = svgLine.node().getTotalLength();
        svgLine
            .attr("stroke-dasharray", svgLineTotalLength + "," + svgLineTotalLength)
            .attr("stroke-dashoffset", svgLineTotalLength)
            .transition()
            .duration(duration)
            .ease(d3[ease])
            .attr("stroke-dashoffset", 0);
      };
      drawLine();
      

      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
// @import "./index.less";
</style>
